// @flow
import * as React from 'react'
import {Row} from '../ui/layout'
import {breakPoints} from '../ui/breakpoints'

import AppStoreBadge from '../images/icon/icon__app-store.svg'
import PlayStoreBadge from '../images/icon/icon__google-play.svg'
import {ExternalOrInternalLink} from '../ui/typo'

const DownloadBadges = () => (
  <Row spacing={1} breakAt={breakPoints.small}>
    <ExternalOrInternalLink
      target="_blank"
      href="https://itunes.apple.com/us/app/superdays/id1328556788?ls=1&mt=8"
    >
      <img alt="App Store Link" src={AppStoreBadge} />
    </ExternalOrInternalLink>
    <ExternalOrInternalLink
      target="_blank"
      href="https://play.google.com/store/apps/details?id=com.superfun.superdays.prod"
    >
      <img alt="Play Store Link" src={PlayStoreBadge} />
    </ExternalOrInternalLink>
  </Row>
)

export default DownloadBadges
