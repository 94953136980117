// @flow
import React from 'react'
import BlogTeaser from '../components/BlogTeaser'
import {StripWithContent, Col} from '../ui/layout'
import typo from '../ui/typo'

type BLSProps = {
  headline: string,
}
const BlogLinkStrip = ({headline}: BLSProps) => (
  <StripWithContent scrollKey="our-story">
    <Col spacing={1} paddingVertical={6}>
      <typo.H2 css={{textAlign: 'center'}}>{headline}</typo.H2>
      <BlogTeaser />
    </Col>
  </StripWithContent>
)

export default BlogLinkStrip
