// @flow
import React from 'react'

import SocialSharing from '../components/SocialSharing'
import DefaultLayout from '../components/DefaultLayout'
import IntroStrip from '../blocks/IntroStrip'
import StepsStrip, {type StepType} from '../blocks/StepsStrip'
import BlogLinkStrip from '../blocks/BlogLinkStrip'
import DownloadBadges from '../components/DownloadBadges'
import {Col} from '../ui/layout'

const steps: StepType[] = [
  {
    title: 'Who’s the lucky one?',
    text:
      'Take some time and think of upcoming birthdays or other occasions that deserve a unique gift.',
  },
  {
    title: 'Ready when you are!',
    text:
      'Once you are ready, you can install Superdays on your phone. Superdays is available for iOS and Android.',
  },
]

const Success = () => (
  <DefaultLayout
    title="Welcome, Superyou!"
    description="We are totally thrilled to share our beta version of Superdays with you."
  >
    <IntroStrip bottomWave="yellow" headline="Welcome, Superyou!">
      <Col spacing={2}>
        You’re now on our mailing list!
        <br />
        We’re lookin forward to share exiting news with you.
      </Col>
    </IntroStrip>
    <StepsStrip headline="What’s next?" steps={steps}>
      <DownloadBadges />
      <SocialSharing />
    </StepsStrip>
    <BlogLinkStrip headline="What do you get again?" />
  </DefaultLayout>
)

export default Success
