// @flow
import * as React from 'react'
import {FullButton} from '../ui/buttons'
import {Col} from '../ui/layout'
import typo from '../ui/typo'

const BlogTeaser = () => (
  <Col spacing={2} paddingVertical={2} align="center">
    <typo.Body css={{textAlign: 'center', maxWidth: 700}}>
      Stephan, the founder of Superdays, explains why giving his friends control over his calendar
      made him experience the most exciting days of his life. ✨
    </typo.Body>
    <FullButton
      to="https://medium.com/@sgabler/this-is-what-happens-when-your-friends-take-over-your-calendar-for-a-week-20ae01653b69"
      target="_blank"
    >
      Read the blog
    </FullButton>
  </Col>
)

export default BlogTeaser
