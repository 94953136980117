// @flow
import * as React from 'react'

import colors from 'superdays-shared/colors'

import {StripWithContent, Col, Row} from '../ui/layout'
import typo, {fontStyles} from '../ui/typo'

import BikeRide from '../images/graph__bike.svg'
import BackgroundBalls from '../images/background-balls.svg'
import BottomCurveImage from '../images/bottom-curve.svg'
import {breakPoints} from '../ui/breakpoints'
import {BottomCurve} from '../ui/shapes'

export type StepType = {|
  title: string,
  text: string,
  action?: React.Node,
|}

const numberStyle = {
  backgroundColor: '#FFF',
  borderRadius: '50%',
  color: colors.periwinkle,
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...fontStyles.bodyBold,
  fontSize: 24,
  flexShrink: 0,
}
const Step = ({number, title, children, action}) => (
  <Col css={{flex: 1}}>
    <Row spacing={1}>
      <div style={numberStyle}>{number}</div>
      <typo.H3 css={{display: 'flex', alignItems: 'center'}}>{title}</typo.H3>
    </Row>
    <Row spacing={1}>
      <div style={{width: 40, height: 40, flexShrink: 0}} />
      <typo.Body css={{maxWidth: 400}}>{children}</typo.Body>
    </Row>
    {action ? (
      <Row padding={2} justify="center">
        {action}
      </Row>
    ) : null}
  </Col>
)

const bikeRideBlockStyle = {
  backgroundImage: `url(${BikeRide}), url(${BottomCurveImage}), url(${BackgroundBalls})`,
  backgroundSize: '500px, calc(100% + 4rem), 640px',
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  height: 450,
  width: '100%',
  [breakPoints.small]: {
    height: 350,
    backgroundSize: '70%, calc(100% + 4rem), 120%',
  },
}

type SSProps = {
  headline: string,
  steps: StepType[],
  children?: React.Node,
  withBikeRide?: boolean,
}
const StepsStrip = ({headline, steps, children, withBikeRide}: SSProps) => (
  <StripWithContent
    scrollKey="steps-strip"
    backgroundGradient={colors.yellowGradient}
    bottom={withBikeRide ? <div css={bikeRideBlockStyle} /> : <BottomCurve />}
  >
    <Col align="center" spacing={3} paddingTop={3} paddingBottom={withBikeRide ? 0 : 8}>
      <typo.H2>{headline}</typo.H2>
      <Row spacing={2} breakAt={breakPoints.medium}>
        {steps.map((step, i) => (
          <Step key={i} number={i + 1} title={step.title} action={step.action}>
            {step.text}
          </Step>
        ))}
      </Row>
      {children}
    </Col>
  </StripWithContent>
)

export default StepsStrip
